.hero-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 20px;
    overflow: hidden;
    z-index: 1;

    @media screen and (max-width: 960px) {
      padding: 80px 16px;
    }

    @media screen and (max-width: 640px) {
      padding: 60px 16px;
    }
  }

  .hero-inner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;

    @media screen and (max-width: 960px) {
      flex-direction: column;
    }
  }

  .hero-left-container {
    flex: 1;
    padding: 20px;

    @media screen and (max-width: 960px) {
      text-align: center;
    }
  }

  .hero-right-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;

    @media screen and (max-width: 960px) {
      margin-top: 40px;
    }
  }

.hero-title {
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 20px;
    border-bottom: 2px solid var(--primary);
    padding-bottom: 10px;
    color: white;

    @media screen and (max-width: 960px) {
      font-size: 2.5rem;
    }
  }

  .hero-text-loop {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    color: white;

    @media screen and (max-width: 960px) {
      justify-content: center;
      font-size: 1.3rem;
    }
  }

  .hero-span {
    margin-left: 8px;
    color: var(--accent);
    cursor: pointer;
  }

  .hero-subtitle {
    font-size: 1.25rem;
    margin-bottom: 30px;
    max-width: 500px;
    line-height: 1.5;
    opacity: 0.85;
    color: white;

    @media screen and (max-width: 960px) {
        text-align: center;
        margin: auto;
    }
}

  .hero-resume-button {
    display: inline-block;
    padding: 16px 32px;
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--primary);
    background: transparent;
    border: 2px solid var(--primary);
    border-radius: 50px;
    transition: all 0.3s ease;
    text-decoration: none;
    cursor: pointer;
    
    &:hover {
      background: var(--primary);
      color: var(--white);
      transform: translateY(-3px);
    }
    
    &:active {
      transform: translateY(0);
    }
    
    @media screen and (max-width: 640px) {
      padding: 12px 24px;
      font-size: 1rem;
    }
  }
  
  .hero-image {
    width: 100%;
    max-width: 400px;
    height: auto;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid var(--primary);
    
    @media screen and (max-width: 768px) {
      max-width: 300px;
    }
    
    @media screen and (max-width: 640px) {
      max-width: 200px;
    }
  }