.about-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px 20px;
    position: relative;
    z-index: 1;
  }
  
  .about-wrapper {
    max-width: 1100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
  }
  
  .about-title {
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 20px;
    border-bottom: 2px solid var(--primary);
    padding-bottom: 10px;
    color: white;
    text-align: center;
  }
  
  .about-desc {
    font-size: 1.25rem;
    text-align: center;
    max-width: 700px;
    color: white;
    opacity: 0.85;
    margin-bottom: 40px;
  }
  
  /* Column layout for content */
  .about-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    align-items: center;
  }
  
  /* Style for the description inside content */
  .about-description {
    font-style: italic;
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 1.5;
    padding: 0 20px;
    text-align: center;
    color: white;
  }
  
  /* Responsive adjustments */
  @media screen and (max-width: 960px) {
    .about-title {
      font-size: 2.5rem;
    }
    .about-desc {
      font-size: 1.1rem;
    }
  }
  
  @media screen and (max-width: 640px) {
    .about-title {
      font-size: 2rem;
    }
    .about-desc {
      font-size: 1rem;
    }
  }
  