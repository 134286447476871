.button {
    display: inline-block;
    padding: 16px 32px;
    font-family: var(--font-sg), sans-serif;
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--primary);
    background: transparent;
    border: 2px solid var(--primary);
    border-radius: 50px;
    transition: all 0.3s ease;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        background: var(--primary);
        color: var(--white);
        transform: translateY(-3px);
    }

    &:active {
        transform: translateY(0);
    }

    @media screen and (max-width: 640px) {
        padding: 12px 24px;
        font-size: 1rem;
    }
}