.about-card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; /* allows wrapping on smaller screens */
    gap: 20px;
    justify-content: center;
    padding: 0 10px;
}

.about-card-section {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    cursor: pointer;
    flex: 0 0 calc(33.33% - 20px); /* three cards per row, adjust gap */

    &:hover {
        transform: translateY(-5px);
    }

    @media screen and (max-width: 960px) {
      flex: 0 0 100%; /* stack cards on medium screens if needed */
    }
}

.about-card-lottie {
    margin-bottom: 15px;
    text-align: center; /* center the lottie element */
}

.about-card-lottie-player {
    width: 100%;
    max-width: 150px;
    height: auto;
    margin: 0 auto;
}

.about-card-content {
    text-align: center;
}

.about-card-name {
    font-size: 1.5rem;
    color: #ffffff;
    margin-bottom: 10px;

    @media screen and (max-width: 640px) {
        font-size: 1.3rem;
    }
}

.about-card-desc {
    font-size: 1rem;
    color: #dddddd;
    line-height: 1.4;

    @media screen and (max-width: 640px) {
        font-size: 0.95rem;
    }
}
