.navbar {
    background-color: var(--pastel-pink);
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 40px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    z-index: 999;
    width: calc(100% - 40px);
    max-width: 1200px;
    height: 80px;
    display: flex;
    align-items: center;
    padding: 0 60px;

    .navbar-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
    }

    /* Logo */
    .navbar-logo {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: white;

        &-img {
            width: 60px;
            height: 60px;
            margin-right: 10px;
        }

        &-text {
            font-size: 1.5rem;
            font-weight: bold;
        }
    }

    /* Mobile Toggle Icon */
    .navbar-toggle {
        display: none;
        color: var(--text-primary);
        font-size: 1.8rem;
        cursor: pointer;

        @media screen and (max-width: 768px) {
            display: block;
        }
    }

    /* Navigation Menu */
    .navbar-menu {
        display: flex;
        gap: 32px;

        .navbar-link {
            color: var(--text-primary);
            font-weight: 500;
            text-decoration: none;
            transition: color 0.2s ease-in-out;

            &:hover {
            color: var(--primary);
            }
        }

        @media screen and (max-width: 768px) {
            display: none;

            &.active {
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 80px; // matches the header height
            right: 0;
            width: 100%;
            padding: 20px;
            background-color: #2C2A2C;
            border-radius: 0 0 40px 40px;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
            }
        }
    }

/* Social Icons */
    .navbar-social {
        display: flex;
        align-items: center;
        gap: 1rem;
        height: 100%;

        .navbar-social-icon {
        font-size: 1.5rem;
        color: var(--text-primary);
        transition: color 0.2s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        }

        @media screen and (max-width: 768px) {
        display: none;
        }
    }
}